const HeroBox = ({ children }) => (
  <div className="flex bg-zinc-100">
    <div className="container mx-auto my-16">{children}</div>
  </div>
)
const HeroTitle = ({ children }) => (
  <p className="text-8xl font-medium mb-12">{children}</p>
)
const HeroHeading = ({ children }) => (
  <p className="text-2xl font-medium mb-6">{children}</p>
)
const HeroBody = ({ children }) => (
  <p className="text-base font-normal mb-8">{children}</p>
)

const Hero = () => {
  return (
    <HeroBox>
      <HeroTitle>Human-centric design and development</HeroTitle>
      <HeroHeading>
        UX/UI design systems, web application development, User Testing, and
        Prototyping.
      </HeroHeading>
      <HeroBody>
        We create web projects that focus on the human user. We start with your
        business needs and your customers' way of thinking. Our design decisions
        are led by experimental results.
      </HeroBody>
    </HeroBox>
  )
}

export default Hero
