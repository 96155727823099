import { phoneInternational } from "./meta"

export default {
  "@context": "http://schema.org",
  "@type": "Organization",
  "@id": "https://redwerks.org#organization",
  url: "https://redwerks.org/",
  name: "Redwerks",
  legalName: "Redwerks",
  description:
    "Redwerks is a web design and web software development company. We specialize in User Interface design (Including Google Material Design). We create web projects that focus on the human user. We start with your business needs and your customers' way of thinking, and ensure your message is clearly formed and properly received. Through our client work, we've created tools that help other companies design and develop websites, and manage their projects.",
  // logo: "",
  telephone: phoneInternational,
  // "email": "",
  contactPoint: [
    {
      "@type": "ContactPoint",
      telephone: phoneInternational,
      contactOption: "TollFree",
    },
  ],
  founder: [
    {
      "@type": "Person",
      name: "Gregory Thomas",
    },
  ],
  member: [
    {
      "@type": "Person",
      name: "Gregory Thomas",
      jobTitle: "Owner, Web Designer, and SEO expert",
      // hasOccupation: [
      //   {
      //     "@type": "Occupation",
      //     url: "https://www.onetonline.org/link/summary/15-1134.00",
      //     name: "Web Developers",
      //     occupationalCategory: {
      //       "@type": "CategoryCode",
      //       name: "Web Developers",
      //       codeValue: "15-1134.00",
      //       inCodeSet: {
      //         "@type": "CategoryCodeSet",
      //         name: "O*Net-SOC",
      //         dateModified: "2019",
      //         url: "https://www.onetonline.org/",
      //       },
      //     },
      //   },
      //   {
      //     "@type": "Occupation",
      //     url: "https://www.onetonline.org/link/summary/15-1199.10",
      //     name: "Search Marketing Strategists",
      //     occupationalCategory: {
      //       "@type": "CategoryCode",
      //       name: "Search Marketing Strategists",
      //       codeValue: "15-1199.10",
      //       inCodeSet: {
      //         "@type": "CategoryCodeSet",
      //         name: "O*Net-SOC",
      //         dateModified: "2019",
      //         url: "https://www.onetonline.org/",
      //       },
      //     },
      //   },
      // ],
    },
    {
      "@type": "Person",
      name: "Daniel Friesen",
      jobtitle:
        "Full-stack web engineer and cross-platform mobile app developer",
      sameAs: [
        "https://danf.ca/",
        "https://www.linkedin.com/in/daniel-friesen-83750231/",
      ],
      // hasOccupation: [
      //   {
      //     "@type": "Occupation",
      //     url: "https://www.onetonline.org/link/summary/15-1131.00",
      //     name: "Computer Programmers",
      //     occupationalCategory: {
      //       "@type": "CategoryCode",
      //       name: "Computer Programmers",
      //       codeValue: "15-1131.00",
      //       inCodeSet: {
      //         "@type": "CategoryCodeSet",
      //         name: "O*Net-SOC",
      //         dateModified: "2019",
      //         url: "https://www.onetonline.org/",
      //       },
      //     },
      //   },
      //   {
      //     "@type": "Occupation",
      //     url: "https://www.onetonline.org/link/summary/15-1134.00",
      //     name: "Web Developers",
      //     occupationalCategory: {
      //       "@type": "CategoryCode",
      //       name: "Web Developers",
      //       codeValue: "15-1134.00",
      //       inCodeSet: {
      //         "@type": "CategoryCodeSet",
      //         name: "O*Net-SOC",
      //         dateModified: "2019",
      //         url: "https://www.onetonline.org/",
      //       },
      //     },
      //   },
      //   {
      //     "@type": "Occupation",
      //     url: "https://www.onetonline.org/link/summary/15-1199.02",
      //     name: "Computer Systems Engineers/Architects",
      //     occupationalCategory: {
      //       "@type": "CategoryCode",
      //       name: "Computer Systems Engineers/Architects",
      //       codeValue: "15-1199.02",
      //       inCodeSet: {
      //         "@type": "CategoryCodeSet",
      //         name: "O*Net-SOC",
      //         dateModified: "2019",
      //         url: "https://www.onetonline.org/",
      //       },
      //     },
      //   },
      // ],
    },
  ],
  brand: ["https://paceology.com/"],
  sameAs: ["http://redwerks.org/", "https://twitter.com/redwerks"],
}
