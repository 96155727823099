import Helmet from "react-helmet"
import organization from "../../organization"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { LayoutMain } from "../components/ui/layout-components"
import Hero from "../home/hero"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(organization, null, 2)}
      </script>
    </Helmet>
    <LayoutMain>
      <Hero />
    </LayoutMain>
  </Layout>
)

export default IndexPage
